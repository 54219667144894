import React from 'react';
import ReactPlayer from 'react-player'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './App.css';

const urlPrefix = process.env.REACT_APP_API_PREFIX;

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, list: [], selected: 0 };
  }

  componentDidMount() {
    fetch(`${urlPrefix}/playlists`)
      .then(res => res.json())
      .then((json) => this.setState({ loading: false, lists: json.playlists }));
  }

  componentDidUpdate() {
    if (this.carouselWrapperRef) {
      this.carouselWrapperRef.focus();
      this.carouselWrapperRef.addEventListener("keydown", (e) => {
        if (e.keyCode === 13) { // Enter
          this.props.onListSelection(this.state.lists[this.state.selected].id)
        }
      });
    }
  }

  renderLists = (lists) => {
    return <Carousel
      showThumbs={false}
      useKeyboardArrows={true}
      ref={(ref) => { if (ref) { this.carouselWrapperRef = ref.carouselWrapperRef } }}
      onKeyPress={(e) => console.log(e.key)}
      showStatus={false}
      onChange={ (n) => this.setState({ selected: n }) }
    >
      { lists.map((l) => this.renderList(l)) }
    </Carousel>
  }

  renderList = (l) => {
    return (
      <div key={l.id} data-testid={`playlist-item-${l.id}`} class="list-item">
        <button data-testid="playlist-link" onClick={() => this.props.onListSelection(l.id)} style={{ cursor: 'pointer', border: 'none', backgroundColor: 'white' }}>
          <img src={l.image} alt={l.name} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
        </button>
        <p style={{ textAlign: "center", margin: 10 }}>{l.description}.</p>
      </div>
    );
  }

  render() {
    return (
      <div data-testid="playlist-list" className="playlist-list">
        <h1>Spotify Featured Playlists in UK right now</h1>
        { this.state.loading ? <p>Loading...</p> : this.renderLists(this.state.lists) }
      </div>
    );
  }
}

class ListPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, songs: [] };
  }

  componentDidMount() {
    fetch(`${urlPrefix}/playlists/${this.props.playlistID}/songs`)
      .then(res => res.json())
      .then((json) => this.setState({ loading: false, songs: json.songs, currentSongIndex: 0 }))
      .catch((err) => { console.log(err) });
  }

  nextSong = () => {
    const nextIndex = this.state.currentSongIndex + 1;
    this.setState({ currentSongIndex: nextIndex });
  }

  render() {
    if (this.state.loading) {
      return (<p>Loading...</p>);
    } else {
      const song = this.state.songs[this.state.currentSongIndex];
      return (<SongPlayer artist={song.artist} name={song.name} onEnded={this.nextSong} />);
    };
  };
}


class SongPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, url: null };
  }

  componentDidMount() {
    const query = (`${this.props.name} ${this.props.artist}`);

    fetch(`${urlPrefix}/video_for_song/${query}`)
      .then(res => res.json())
      .then((json) => this.setState({ loading: false, url: json.url }))
      .catch((err) => { console.log(err) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name || this.props.artist !== prevProps.artist) {
      const query = (`${this.props.name} ${this.props.artist}`);
      fetch(`${urlPrefix}/video_for_song/${query}`)
        .then(res => res.json())
        .then((json) => this.setState({ url: json.url }))
        .catch((err) => { console.log(err) });
    }
  }

  render() {
    if (this.state.loading) {
      return (<p>Loading { this.props.name} from { this.props.artist}</p>);
    } else {
      return (
        <div data-testid="player" className="player">
          <ReactPlayer url={this.state.url} playing controls onEnded={this.props.onEnded} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} width='100%' height='100%' />
        </div>
      );
    };
  };
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { listId: null };
  }

  render() {
    if (this.state.listId) {
      return (<ListPlayer playlistID={this.state.listId} />);
    } else {
      return (<List onListSelection={(listId) => this.setState({ listId: listId })} />);
    }
  }
}

export default App;
